function createSale(saleObj) {

  const sale = {

    id : saleObj.id,

    operation : saleObj.operation,
    
    customer : saleObj.customer,
    
    salesman : saleObj.salesman,
    
    sale_products : saleObj.sale_products, 
    
    sale_payments : saleObj.sale_payments, 
    
    general_discount : saleObj.general_discount,

    shipping : 0,

    expenses : 0,
    
    status : saleObj.status,

    get total() {
      return this.sale_products.reduce((total, product) => {
        return total + parseFloat(product.quantity) * parseFloat(product.price);
      }, 0);
    },

    get general_discount_percent() {
      return this.total > 0 ? (this.general_discount / this.total) * 100 : 0;
    },

    get net_total() {
      return this.total - parseFloat(this.general_discount);
    },

    getSale() {
      // for sale_products

      for (let saleProduct of this.sale_products) {
        
        saleProduct.product_total = saleProduct.quantity * saleProduct.price;     
        
        saleProduct.product_sub_total = (saleProduct.quantity * saleProduct.price) - saleProduct.product_discount;
      }

      const productSubTotalsArr = this.sale_products.map(row => row.product_sub_total)

      const generalDiscountArr = this.calcGeneralDiscountArr(productSubTotalsArr, this.general_discount);

      for (let i in this.sale_products) {

        this.sale_products[i].general_discount = generalDiscountArr[i]

        //this.sale_products[i].sub_total = Number(this.sale_products[i].product_total) - Number(this.sale_products[i].product_discount)

        this.sale_products[i].discount = Number(this.sale_products[i].product_discount) + Number(this.sale_products[i].general_discount)

        this.sale_products[i].net_total = Number(this.sale_products[i].product_total) - Number(this.sale_products[i].discount)

        //this.sale_products[i].net_price = Number(this.sale_products[i].net_total) / Number(this.sale_products[i].quantity)
      }

      return this;

    },

    calcGeneralDiscountArr(productSubTotalsArr, general_discount) {

      const sum = productSubTotalsArr.reduce((total, productSubTotal) => {
        return total + productSubTotal;
      }, 0);


      var discountArr = productSubTotalsArr.map(subTotal => { 
        
        if (sum === 0) return 0;

        return Math.round(((subTotal / sum) * general_discount) * 100) / 100
      
      });
      
      const difference = general_discount - discountArr.reduce((a, b) => { return a + b; }, 0);

      discountArr[0] = Math.round((discountArr[0] + difference) * 100) / 100;

      return discountArr;
    } 
  };

  //return sale;

  return new Proxy(sale, {
    set(target, property, value) {
      
      target[property] = value;

      return true;
    },
  });
}

export default createSale;
